import React from 'react';

export default () => (
  <React.Fragment>
    <div className="jumbotron">
      <div className="content container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="display-4">V-flow</h1>
            <p className="lead">Social Project and Task Management</p>
            <p className="summary">
              Plan and manage work in a rapidly changing world. Easily decompose
              and organize goals in a network that seamlessly spans individuals,
              teams, and even companies.
            </p>
            <p>
              <a href="/#/goals" className="btn btn-primary btn-lg">
                Get started
              </a>
            </p>
          </div>

          <div className="col-md-6" style={{ backgroundColor: '#00000033' }}>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
    <div className="content container">
      <div className="row">
        <div className="col-md-4">
          <h2>Prioritize Goals</h2>
          <p>
            Prioritization automatically combines a dynamic model value and
            duration, and is automatically propagated through the relevant
            network of goals, all the way to the task level.
          </p>
        </div>
        <div className="col-md-4">
          <h2>Visualize Status</h2>
          <p>
            Users organize goals and dependencies in a graphical network
            structure where goals and sub-trees are easy to detach and reconnect
            in new ways.
          </p>
        </div>
        <div className="col-md-4">
          <h2>Stay Flexible</h2>
          <p>
            When needs change, as they always do, V-flow will automatically
            adapt to present the highest priorities in an easy to interpret
            graphical format.
          </p>
        </div>
      </div>
    </div>
  </React.Fragment>
);
