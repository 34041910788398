import React from 'react';
import * as d3 from 'd3';

const convertGoalsToGraph = goals => {
  const graph = { nodes: [], links: [] };
  goals.forEach(goal => {
    graph.nodes.push({ id: goal.id, vfr: goal.vfr, goal: goal.goal });
    goal.children.forEach(child => {
      graph.links.push({ source: goal.id, target: child });
    });
  });
  return graph;
};

class ForceDirectedGraph extends React.Component {
  componentDidMount() {
    const maxVfr = this.props.goals[0].vfr || 1;
    const graph = convertGoalsToGraph(this.props.goals);

    const svg = d3.select('svg');
    const width = 960;
    const height = 600;

    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3
          .forceLink()
          .id(d => d.id)
          .distance(() => 200),
      )
      .force('charge', d3.forceManyBody())
      .force('center', d3.forceCenter(width / 2, height / 2));

    const link = svg
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graph.links)
      .enter()
      .append('line')
      .attr('stroke-width', 5);

    const node = svg
      .append('g')
      .attr('class', 'nodes')
      .selectAll('circle')
      .data(graph.nodes)
      .enter()
      .append('circle')
      .attr('r', d => 10 + 20 * (d.vfr / maxVfr))
      .attr('fill', '#008080');

    node.append('title').text(d => d.goal);

    function ticked() {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node.attr('cx', d => d.x).attr('cy', d => d.y);
    }

    simulation.nodes(graph.nodes).on('tick', ticked);

    simulation.force('link').links(graph.links);
  }

  shouldComponentUpdate() {
    // Prevents component re-rendering
    return false;
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    return (
      <svg
        className="visualization"
        height="auto"
        ref={this._setRef.bind(this)}
        style={{ backgroundColor: '#00000033' }}
        viewBox="0 0 960 600"
        width="100%"
      />
    );
  }
}

export default ForceDirectedGraph;
