import firebase from '@firebase/app';
import '@firebase/firestore';
import Goal from '../entities/goal';

const productionConfig = {
  apiKey: 'AIzaSyBrlgT-08vhckypmCwhvZuIHMhb94utBC8',
  authDomain: 'vflow-2e988.firebaseapp.com',
  databaseURL: 'https://vflow-2e988.firebaseio.com',
  projectId: 'vflow-2e988',
  storageBucket: 'vflow-2e988.appspot.com',
  messagingSenderId: '70610726869',
};

const devConfig = {
  apiKey: 'AIzaSyDRlkMeGsk1T6eDoC2g7xWtezUhvAhKa4g',
  authDomain: 'vflow-io-dev.firebaseapp.com',
  databaseURL: 'https://vflow-io-dev.firebaseio.com',
  projectId: 'vflow-io-dev',
  storageBucket: 'vflow-io-dev.appspot.com',
  messagingSenderId: '1083069261298',
};

/* eslint no-undef: 0 */
const ENV = process.env.VFLOW_PERSISTENCE || VFLOW_PERSISTENCE;

if (ENV === 'prod') {
  firebase.initializeApp(productionConfig);
} else {
  firebase.initializeApp(devConfig);
}

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

const FirestorePersistance = {
  getGoals: () =>
    new Promise(async (resolve, reject) => {
      try {
        const results = [];
        const docs = await db
          .collection('goals')
          .orderBy('vfr', 'desc')
          .get();
        docs.forEach(doc => {
          const rawGoal = Object.assign(doc.data(), { id: doc.id });
          results.push(new Goal(rawGoal));
        });
        resolve(results);
      } catch (error) {
        reject(error);
      }
    }),

  getGoal: goalId =>
    new Promise(async (resolve, reject) => {
      try {
        const doc = await db
          .collection('goals')
          .doc(goalId)
          .get();
        const rawGoal = Object.assign(doc.data(), {
          id: goalId,
        });
        resolve(new Goal(rawGoal));
      } catch (error) {
        reject(error);
      }
    }),

  saveGoal: updatedGoal =>
    new Promise(async (resolve, reject) => {
      try {
        await db
          .collection('goals')
          .doc(updatedGoal.id)
          .set({
            children: updatedGoal.children,
            consumer: updatedGoal.consumer,
            descriptionMarkdown: updatedGoal.descriptionMarkdown,
            descriptionUrl: updatedGoal.descriptionUrl,
            doneDate: updatedGoal.doneDate,
            duration: updatedGoal.duration,
            goal: updatedGoal.goal,
            value: updatedGoal.value,
            vfr: updatedGoal.getValueFlowRate(),
          });
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  createGoal: newGoal =>
    new Promise(async (resolve, reject) => {
      try {
        await db.collection('goals').add({
          children: newGoal.children,
          consumer: newGoal.consumer,
          descriptionMarkdown: newGoal.descriptionMarkdown,
          descriptionUrl: newGoal.descriptionUrl,
          duration: newGoal.duration,
          goal: newGoal.goal,
          value: newGoal.value,
          vfr: newGoal.getValueFlowRate(),
          doneDate: newGoal.doneDate,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  deleteGoal: goal =>
    new Promise(async (resolve, reject) => {
      try {
        await db
          .collection('goals')
          .doc(goal.id)
          .delete();
        resolve();
      } catch (error) {
        reject(error);
      }
    }),
};

export default FirestorePersistance;
