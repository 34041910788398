import Controller from '../business/controller';

const fetchGoals = async () => {
  const goals = await Controller.getGoals();
  return { goals };
};

const setGoals = ({ state, props }) => {
  state.set('goals', props.goals);
};

export const getGoals = [fetchGoals, setGoals];

const fetchGoal = async context => {
  const goal = await Controller.getGoal(context.props.goalId);
  return { goal };
};

const setGoal = ({ state, props }) => {
  state.set('goal', props.goal);
};

export const getGoal = [fetchGoal, setGoal];

export const getNewGoal = context => {
  context.state.set('goal', Controller.getNewGoal());
};

export const saveGoal = context => {
  Controller.saveGoal(context.state.get('goal'));
};

export const updateGoal = context => {
  const rawGoal = context.state.get('goal');
  const goal = Controller.updateGoal(rawGoal);
  context.state.set('goal', goal);
};

export const createGoal = context => {
  Controller.createGoal(context.state.get('goal'));
};

export const deleteGoal = context => {
  Controller.deleteGoal(context.state.get('goal'));
};
