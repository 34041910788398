import Router from '@cerebral/router';

export default Router({
  routes: [
    {
      path: '/',
      signal: 'homeViewRouted',
    },
    {
      path: '/goals',
      signal: 'goalsViewRouted',
    },
    {
      path: '/goals/new',
      signal: 'goalNewRouted',
    },
    {
      path: '/goals/:goalId/edit',
      signal: 'goalEditRouted',
    },
  ],
  onlyHash: true,
});
