import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import React from 'react';

import ForceDirectedGraph from './ForceDirectedGraph';

export default connect(
  {
    goals: state`goals`,
  },
  ({ goals }) => (
    <div className="content container">
      <h2>Goals</h2>
      <ForceDirectedGraph goals={goals} />
      <table className="table">
        <thead>
          <tr>
            <th>Goal</th>
            <th>VFR</th>
            <th>Value</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {goals.map(goal => (
            <tr key={goal.id} className={goal.doneDate ? 'complete' : ''}>
              <td>
                <a href={`/#/goals/${goal.id}/edit`}>{goal.goal}</a>
              </td>
              <td>{goal.vfr}</td>
              <td>{goal.value}</td>
              <td>{goal.duration}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <a href="/#/goals/new" className="btn btn-primary">
        New Goal
      </a>
    </div>
  ),
);
