import { Module } from 'cerebral';
import { set } from 'cerebral/operators';
import { state, props, string } from 'cerebral/tags';
import { goTo } from '@cerebral/router/operators';
import router from './router';
import {
  createGoal,
  deleteGoal,
  getGoal,
  getGoals,
  getNewGoal,
  saveGoal,
  updateGoal,
} from './actions';

export default Module({
  modules: { router },
  state: {
    currentPage: 'Loading',
    form: {
      validate: false,
      submitted: false,
    },
    goals: [],
    goal: undefined,
  },
  signals: {
    goalsViewRouted: [getGoals, set(state`currentPage`, 'GoalsCollection')],
    goalNewRouted: [
      getNewGoal,
      set(state`form.validate`, false),
      set(state`currentPage`, 'GoalNew'),
    ],
    goalEditRouted: [
      getGoal,
      getGoals,
      set(state`form.validate`, false),
      set(state`currentPage`, 'GoalEdit'),
    ],
    clickGoalDelete: [deleteGoal, goTo(string`/goals`)],
    clickGoalSubmit: [set(state`form.validate`, true)],
    createGoal: [createGoal, goTo(string`/goals`)],
    homeViewRouted: [set(state`currentPage`, 'Home')],
    saveGoal: [saveGoal, goTo(string`/goals`)],
    updateGoalValue: [set(state`goal.${props`key`}`, props`value`), updateGoal],
  },
});
