import Goal from '../entities/goal';
import PersistanceLocalStorage from './persistance-local-storage';
import PersistanceFirestore from './persistance-firebase';

let Persistance;

/* eslint no-undef: 0 */
const ENV = process.env.VFLOW_PERSISTENCE || VFLOW_PERSISTENCE;

if (ENV === 'local') {
  Persistance = PersistanceLocalStorage;
} else {
  Persistance = PersistanceFirestore;
}

const Controller = {
  getGoals: () =>
    new Promise(async (resolve, reject) => {
      try {
        const goals = await Persistance.getGoals();
        const plainGoals = [];
        goals.forEach(goal => {
          plainGoals.push(goal.exportPlainObject());
        });
        resolve(plainGoals);
      } catch (error) {
        reject(error);
      }
    }),

  getGoal: goalId =>
    new Promise(async (resolve, reject) => {
      try {
        const goal = await Persistance.getGoal(goalId);
        resolve(goal.exportPlainObject());
      } catch (error) {
        reject(error);
      }
    }),

  getNewGoal: () => {
    const goal = new Goal();
    return goal.exportPlainObject();
  },

  createGoal: rawGoal =>
    new Promise(async (resolve, reject) => {
      try {
        const goal = new Goal(rawGoal);
        Persistance.createGoal(goal);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  updateGoal: rawGoal => {
    const goal = new Goal(rawGoal);
    return goal.exportPlainObject();
  },

  saveGoal: rawGoal =>
    new Promise(async (resolve, reject) => {
      try {
        const goal = new Goal(rawGoal);
        await Persistance.saveGoal(goal);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  deleteGoal: rawGoal =>
    new Promise(async (resolve, reject) => {
      try {
        const goal = new Goal(rawGoal);
        await Persistance.deleteGoal(goal);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),
};

export default Controller;
