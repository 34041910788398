import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import React from 'react';

import GoalsCollection from './GoalsCollection';
import GoalForm from './GoalForm';
import Home from './Home';

const pages = {
  GoalsCollection,
  GoalEdit: GoalForm,
  GoalNew: GoalForm,
  Home,
  Loading: React.Fragment,
};

export default connect(
  {
    currentPage: state`currentPage`,
  },
  /* eslint-disable prefer-arrow-callback */
  function App({ currentPage }) {
    const Page = pages[currentPage];
    return (
      <React.Fragment>
        <header>
          <nav className="navbar navbar-dark bg-dark">
            <div className="container">
              <span className="navbar-brand mb-0 h1">V-flow</span>
            </div>
          </nav>
        </header>
        <main role="main">
          <Page />
        </main>
        <footer>
          <p>Copyright 2018 VFLOW LLC</p>
        </footer>
      </React.Fragment>
    );
  },
);
