import { connect } from '@cerebral/react';
import { state, signal } from 'cerebral/tags';
import React from 'react';
import ReactMarkdown from 'react-markdown';

export default connect(
  {
    clickGoalDelete: signal`clickGoalDelete`,
    clickGoalSubmit: signal`clickGoalSubmit`,
    createGoal: signal`createGoal`,
    currentPage: state`currentPage`,
    form: state`form`,
    goal: state`goal`,
    goals: state`goals`,
    saveGoal: signal`saveGoal`,
    updateGoalValue: signal`updateGoalValue`,
  },
  ({
    clickGoalDelete,
    clickGoalSubmit,
    createGoal,
    currentPage,
    form,
    goal,
    goals,
    saveGoal,
    updateGoalValue,
  }) => (
    <div className="content container">
      {currentPage === 'GoalNew' && <h2>New Goal</h2>}
      {currentPage === 'GoalEdit' && <h2>Edit Goal</h2>}
      {/* <p>{JSON.stringify(goal)}</p> */}
      {/* <p>{JSON.stringify(form)}</p> */}
      <form
        noValidate
        onSubmit={e => {
          e.preventDefault();
          // TODO: refactor this into the action ^
          clickGoalSubmit();
          if (goal.valid) {
            if (currentPage === 'GoalNew') {
              createGoal();
            } else if (currentPage === 'GoalEdit') {
              saveGoal();
            }
          }
        }}
      >
        <div className="row">
          <div className="form-group col-7">
            <label htmlFor="goal">Goal</label>
            <input
              className={`form-control ${
                form.validate && goal.validationMessages.goal ? 'error' : ''
              }`}
              id="goal"
              name="goal"
              required
              type="text"
              value={goal.goal}
              onChange={e =>
                updateGoalValue({ key: e.target.name, value: e.target.value })
              }
            />
            {form.validate &&
              goal.validationMessages.goal && (
                <div id="goalHelp" className="error">
                  {goal.validationMessages.goal}
                </div>
              )}
            <small id="goalHelp" className="form-text text-muted">
              A goal is usually a short verb phrase, e.g., &quot;Increase 2018
              revenue by 5%&quot; or &quot;Buy a new house&quot;.
            </small>
          </div>
          <div className="form-group col">
            <label htmlFor="value">Value</label>
            <input
              className="form-control"
              id="value"
              name="value"
              required
              step="0.1"
              type="number"
              value={goal.value}
              onChange={e =>
                updateGoalValue({
                  key: e.target.name,
                  value: e.target.value,
                })
              }
            />
            <small id="valueHelp" className="form-text text-muted">
              Relative cost each day that this is not achieved.
            </small>
          </div>
          <div className="form-group col">
            <label htmlFor="duration">Duration</label>
            <input
              className="form-control"
              id="duration"
              name="duration"
              required
              step="0.1"
              type="number"
              value={goal.duration}
              onChange={e =>
                updateGoalValue({
                  key: e.target.name,
                  value: e.target.value,
                })
              }
            />
            <small id="durationHelp" className="form-text text-muted">
              Relative wall-clock time required to complete this once it’s
              started if it is the sole focus.
            </small>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-8">
            <label htmlFor="descriptionUrl">Description URL</label>
            <input
              className="form-control"
              id="descriptionUrl"
              name="descriptionUrl"
              type="text"
              value={goal.descriptionUrl}
              onChange={e =>
                updateGoalValue({ key: e.target.name, value: e.target.value })
              }
            />
            <small id="descriptionUrlHelp" className="form-text text-muted">
              URL to a page with more information
            </small>
          </div>

          <div className="form-group col-4">
            <label htmlFor="doneDate">Done Date</label>
            <input
              className="form-control"
              id="doneDate"
              name="doneDate"
              type="date"
              value={goal.doneDate}
              onChange={e =>
                updateGoalValue({ key: e.target.name, value: e.target.value })
              }
            />
            <small id="doneDateHelp" className="form-text text-muted">
              Date that the goal was completed
            </small>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="descriptionMarkdown">Description Markdown</label>
            <textarea
              className="form-control"
              id="descriptionMarkdown"
              name="descriptionMarkdown"
              value={goal.descriptionMarkdown}
              onChange={e =>
                updateGoalValue({ key: e.target.name, value: e.target.value })
              }
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="descriptionMarkdown">Description</label>
            <div className="form-control description">
              <ReactMarkdown source={goal.descriptionMarkdown} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-4">
            <label htmlFor="consumer">Consumer</label>
            <input
              className={`form-control ${
                form.validate && goal.validationMessages.consumer ? 'error' : ''
              }`}
              id="consumer"
              name="consumer"
              required
              type="text"
              value={goal.consumer}
              onChange={e =>
                updateGoalValue({ key: e.target.name, value: e.target.value })
              }
            />
            {form.validate &&
              goal.validationMessages.consumer && (
                <div id="goalHelp" className="error">
                  {goal.validationMessages.consumer}
                </div>
              )}
            <small id="consumerHelp" className="form-text text-muted">
              Individual blah blah
            </small>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="children">Children</label>
            <select
              className="form-control"
              id="children"
              multiple
              name="children"
              value={goal.children}
              onChange={e => {
                updateGoalValue({
                  key: e.target.name,
                  value: [...e.target.selectedOptions].map(o => o.value),
                });
              }}
            >
              {goals.map(goalOption => (
                <React.Fragment key={goalOption.id}>
                  {goal.id !== goalOption.id && (
                    <option value={goalOption.id}>
                      {goalOption.consumer}: {goalOption.goal}
                    </option>
                  )}
                </React.Fragment>
              ))}
            </select>
            <small id="childrenHelp" className="form-text text-muted">
              Children help
            </small>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Save Goal
        </button>
        <a href="/#/goals" className="btn btn-secondary">
          Cancel
        </a>
        {currentPage === 'GoalEdit' && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => clickGoalDelete()}
          >
            Delete Goal
          </button>
        )}
      </form>
    </div>
  ),
);
