import _ from 'lodash';

function Goal({
  children = [],
  consumer = '',
  descriptionMarkdown = '',
  descriptionUrl = '',
  doneDate = '',
  duration = 1,
  goal = '',
  id = `local${Date.now()}`,
  value = 1,
} = {}) {
  this.children = children;
  this.consumer = consumer;
  this.descriptionMarkdown = descriptionMarkdown;
  this.descriptionUrl = descriptionUrl;
  this.doneDate = doneDate;
  this.duration = parseFloat(duration);
  this.goal = goal;
  this.id = id;
  this.validationMessages = [];
  this.value = parseFloat(value);
}

Goal.prototype.getValueFlowRate = function getValueFlowRate() {
  return (this.value / this.duration).toFixed(2);
};

Goal.prototype.isValid = function isValid() {
  this.validationMessages = {};
  if (!this.goal) {
    this.validationMessages.goal = 'Goal description is required.';
  }
  if (!this.consumer) {
    this.validationMessages.consumer = 'Consumer name is required.';
  }
  return (
    !!this.goal &&
    !!this.consumer &&
    _.isNumber(this.duration) &&
    _.isNumber(this.value) &&
    _.isString(this.consumer) &&
    _.isString(this.goal) &&
    _.isString(this.id)
  );
};

Goal.prototype.exportPlainObject = function exportPlainObject() {
  return {
    children: this.children,
    consumer: this.consumer,
    descriptionMarkdown: this.descriptionMarkdown,
    descriptionUrl: this.descriptionUrl,
    doneDate: this.doneDate,
    duration: this.duration,
    goal: this.goal,
    id: this.id,
    valid: this.isValid(),
    validationMessages: this.validationMessages,
    value: this.value,
    vfr: this.getValueFlowRate(),
  };
};

export default Goal;
