import Goal from '../entities/goal';

const GOALS_KEY = 'GOALS';

const getGoalsFromLocalStorage = () => {
  const goalsString = localStorage.getItem(GOALS_KEY) || '[]';
  const rawGoals = JSON.parse(goalsString);
  const results = [];
  rawGoals.forEach(rawGoal => {
    results.push(
      new Goal({
        consumer: rawGoal.consumer,
        doneDate: rawGoal.doneDate,
        descriptionMarkdown: rawGoal.descriptionMarkdown,
        descriptionUrl: rawGoal.descriptionUrl,
        duration: rawGoal.duration,
        goal: rawGoal.goal,
        id: rawGoal.id,
        value: rawGoal.value,
      }),
    );
  });
  return results;
};

const getGoalFromLocalStorage = id => {
  const goal = getGoalsFromLocalStorage().find(item => item.id === id);
  return goal;
};

const deleteGoalFromLocalStorage = id => {
  const goals = getGoalsFromLocalStorage();
  const goal = goals.find(item => item.id === id);
  const index = goals.indexOf(goal);
  goals.splice(index, 1);
  localStorage.setItem(GOALS_KEY, JSON.stringify(goals));
};

const saveGoalsToLocalStorage = goals => {
  goals.sort((a, b) => a.getValueFlowRate() < b.getValueFlowRate());
  localStorage.setItem(GOALS_KEY, JSON.stringify(goals));
};

const LocalStoragePersistance = {
  getGoals: () =>
    new Promise((resolve, reject) => {
      try {
        resolve(getGoalsFromLocalStorage());
      } catch (error) {
        reject(error);
      }
    }),

  getGoal: goalId =>
    new Promise((resolve, reject) => {
      try {
        resolve(getGoalFromLocalStorage(goalId));
      } catch (error) {
        reject(error);
      }
    }),

  saveGoal: updatedGoal =>
    new Promise((resolve, reject) => {
      try {
        const goals = getGoalsFromLocalStorage();
        const goal = goals.find(item => item.id === updatedGoal.id);
        goal.consumer = updatedGoal.consumer;
        goal.descriptionMarkdown = updatedGoal.descriptionMarkdown;
        goal.descriptionUrl = updatedGoal.descriptionUrl;
        goal.doneDate = updatedGoal.doneDate;
        goal.duration = parseFloat(updatedGoal.duration);
        goal.goal = updatedGoal.goal;
        goal.value = parseFloat(updatedGoal.value);
        saveGoalsToLocalStorage(goals);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  createGoal: newGoal =>
    new Promise((resolve, reject) => {
      try {
        const goals = getGoalsFromLocalStorage();
        goals.push(newGoal);
        saveGoalsToLocalStorage(goals);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),

  deleteGoal: goal =>
    new Promise((resolve, reject) => {
      try {
        resolve(deleteGoalFromLocalStorage(goal.id));
      } catch (error) {
        reject(error);
      }
    }),
};

export default LocalStoragePersistance;
